import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
import moment from "moment";
import Select from "react-select";
import $ from "jquery";
import LightBox from "./LightBox";

import FsLightbox from "fslightbox-react";

import {
	getUserListById,
	getEnterContestListById,
	getContestDetails,
	getPastContestListById,
	getKycProcess,
	getSuspendUser,
	getKycProcessReject,
	getwithdrawListById,
	getusercontest,
	addMoneyOrRefund,
	getPastContestListForRefund,
	acceptOrRejectKyc,
	getMaritalStatus,
} from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import RightSidebar from "./Popup/RightSidebar";
import { Player } from "video-react";
import video from "../assets/videos/video.mp4";
import imgg from "../assets/images/bank.jpg";
import Plyr from "plyr";

// import logo from "../../assets/images/whitelogo.png";

const unitmapping = {
	days: 24 * 60 * 60 * 1000,
	hours: 60 * 60 * 1000,
	minutes: 60 * 1000,
	seconds: 1000,
};

const types = [
	{ value: "", label: "Refund" },
	{ value: "addmoney", label: "Bonus" },
];

const action = [
	{ value: "1", label: "Accept" },
	{ value: "0", label: "Reject" },
];

// import "~video-react/dist/video-react.css"; // import css

class UserDetail extends Component {
	state = {
		sortIs: false,
		currentPage: 1,
		LoadingIs: false,
		userListData: [],
		maritalStatus: [],
		totalPages: 0,
		aadhaarImage: "",
		panCardImage: "",
		totalPagesIs: 0,
		totalPagesAre: 0,
		userListSearch: "",
		listSearch: "",
		userId: "",
		enteredContestList: [],
		pastContestListcancle: [],
		enterContestlive: [],
		enterContestcombo: [],
		pastContestList: [],
		enterContestSearch: "",
		pastContestSearch: "",
		withdrawSearch: "",
		contestDetailData: [],
		loadingAre: false,
		LoadingPast: false,
		currentPagePast: 1,
		currentPagewithdraw: 1,
		kycStatus: 1,
		comment: "",
		errorIs: { comment: "" },
		currentTime: "",
		LoadingWithdraw: false,
		withdrawList: [],
		count: 0,
		indexCount: 0,
		indexCountPast: 0,
		indexCountTrans: 0,
		showButton: false,
		portUserId: "",
		portContestId: "",
		selectType: { value: "", label: "Refund" },
		selectContest: "",
		ContestValue: "",
		contestList: [],
		amountRefund: 0,
		amountPaid: "",
		commentRefund: "",
		errorAre: {
			amountPaid: "",
			commentRefund: "",
			selectContest: "",
		},
		totalPagesPayment: 0,
		paymentSearch: "",
		paymentList: [],
		modalAmount: "",
		modalStatus: "",
		modalComments: "",
		currentPagePayment: 1,
		showRefresh: false,
		chechPrize: 2,
		documentType: "",
		copyresponse: [],
		verifyadhar: "",
		verifypen: "",
		activeadhar: "",
		activepen: "",
	};

	// video carousel jquery

	//  video jquery end

	// handleEnterContestSearch = (e) => {
	//   e.preventDefault();
	//   this.setState({ enterContestSearch: e.target.value }, () => {
	//     if (e.target.value.length > 2) {
	//       this.handleEnterContestListById(
	//         this.state.userId,
	//         this.state.currentPage,
	//         this.state.enterContestSearch
	//       );
	//     } else if (e.target.value.length === 0) {
	//       this.handleEnterContestListById(
	//         this.state.userId,
	//         this.state.currentPage,
	//         this.state.enterContestSearch
	//       );
	//     }
	//   });
	// };

	handleKyc = async (e) => {
		if (this.state.userId && this.state.documentType.value && e) {
			const response = await acceptOrRejectKyc(e, this.state.userId, this.state.documentType.value);

			if (response) {
				toast.success(response.message);
			} else {
				toast.error(response.message);
			}
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			toast.error("Please Select The Selection");
		}
	};

	compareBy = (key) => {
		return function (a, b) {
			if (a[key] < b[key]) return -1;
			if (a[key] > b[key]) return 1;
			return 0;
		};
	};

	sortBy = (key) => {
		let arrayCopy = [...this.state.enteredContestList];
		arrayCopy.sort(this.compareBy(key));
		this.setState({ enteredContestList: arrayCopy });
	};

	sortByPast = (key) => {
		let arrayCopy = [...this.state.pastContestList];
		arrayCopy.sort(this.compareBy(key));
		this.setState({ pastContestList: arrayCopy });
	};

	sortByWithdraw = (key) => {
		let arrayCopy = [...this.state.withdrawList];
		arrayCopy.sort(this.compareBy(key));
		this.setState({ withdrawList: arrayCopy });
	};

	sortByPayment = (key) => {
		let arrayCopy = [...this.state.paymentList];
		arrayCopy.sort(this.compareBy(key));
		this.setState({ paymentList: arrayCopy });
	};

	handleWithdrawSearch = (e) => {
		e.preventDefault();
		this.setState({ withdrawSearch: e.target.value }, () => {
			if (e.target.value.length > 2) {
				this.handleWithdrawList(this.state.userId, this.state.currentPagewithdraw, this.state.withdrawSearch);
			} else if (e.target.value.length === 0) {
				this.handleWithdrawList(this.state.userId, this.state.currentPagewithdraw, this.state.withdrawSearch);
			}
		});
	};

	handleTotalPortfolioPoints = (portfolio) => {
		if (portfolio.length) {
			let points = 0;
			portfolio.forEach((element) => {
				points = points + element.points;
			});
			return Math.floor(points);
		} else return 0;
	};

	handlePaymentSearch = (e) => {
		e.preventDefault();
		this.setState({ PaymentSearch: e.target.value }, () => {
			if (e.target.value.length > 2) {
				this.handlePaymentId(this.state.userId, this.state.currentPagePayment, this.state.PaymentSearch);
			} else if (e.target.value.length === 0) {
				this.handlePaymentId(this.state.userId, this.state.currentPagePayment, this.state.PaymentSearch);
			}
		});
	};

	getHumanizedDiff(diff) {
		let days = Math.floor(diff / unitmapping.days);
		let hours = Math.floor((diff % unitmapping.days) / unitmapping.hours);
		let minutes = Math.floor((diff % unitmapping.hours) / unitmapping.minutes);
		if (days) {
			return days + " days " + hours + " hrs " + minutes + " min ";
		} else if (hours) {
			return hours + " hrs " + minutes + " min ";
		} else {
			return minutes + " min ";
		}
	}
	handleUserListById = async (id) => {
		let response = await getUserListById(id);
		this.setState({ copyresponse: response.data });
		if (response.status === 1) {
			let res = response.data;
			let showButton = true;
			this.setState({
				userListData: res,
				showButton: showButton,
			});
		} else {
			toast.error(response.msg);
		}
	};

	handleMaritalStatus = async (id) => {
		let response = await getMaritalStatus(id);
		this.setState({
			copyresponse: response.data,
		});
		if (response.status === 1) {
			let res = response.data;

			let showButton = true;
			this.setState({
				maritalStatus: res,
				showButton: showButton,
			});
		} else {
			toast.error(response.msg);
		}
	};

	handleEnterContestListById = async (id, page, search) => {
		let response = await getEnterContestListById(id, page, search);
		if (response.status === 1) {
			let res = response?.data ? response.data : [];
			this.setState({
				enteredContestList: res,
				totalPages: response?.totalPages || 1,
				LoadingIs: false,
			});
		} else {
			this.setState({ LoadingIs: false }, () => {
				toast.error(response.msg);
			});
		}
	};

	handlePastContestListForRefund = async (id) => {
		let response = await getPastContestListForRefund(id);
		if (response.status === 1) {
			let res = response?.data ? response.data : [];
			const result = res.filter((item) => item.balanceCheck === true);
			let array = [];
			result.forEach((item, i) => {
				array.push({
					value: `${item.contestId}=${item.walletBalance}`,
					label: `${item.contestId}-${item.contestName}`,
				});
			});
			this.setState({ contestList: array });
		} else {
			toast.error("SomeThing Went Wrong");
		}
	};

	handlePastContestListById = async (id, page, search) => {
		let response = await getPastContestListById(id, page, search);
		if (response.status === 1) {
			let res = response?.data ? response.data : [];
			this.setState({
				pastContestList: res,
				totalPagesIs: response?.totalPages || 1,
				LoadingPast: false,
			});
		} else {
			this.setState({ LoadingPast: false }, () => {
				toast.error(response.msg);
			});
		}
	};

	handleWithdrawList = async (id, page, search) => {
		this.setState({ LoadingWithdraw: false });
		let response = await getwithdrawListById(id, page, search);
		if (response.status === 1) {
			let res = response?.data ? response.data : [];
			this.setState({
				withdrawList: res,
				LoadingWithdraw: false,
			});
		} else {
			this.setState({ LoadingWithdraw: false }, () => {
				toast.error(response.msg);
			});
		}
	};

	handlePageClick = (data) => {
		let selectedData = data.selected + 1;
		this.setState({ indexCount: data.selected }, () => {
			this.handleEnterContestListById(this.state.userId, selectedData, this.state.enterContestSearch);
		});
	};

	handlePageClickIs = (data) => {
		let selectedDataIs = data.selected + 1;
		this.setState({ indexCountPast: data.selected }, () => {
			this.handlePastContestListById(this.state.userId, selectedDataIs, this.state.pastContestSearch);
		});
	};

	handlePageClickPayment = (data) => {
		let selectedDataPay = data.selected + 1;
		this.setState({ indexCountPayment: data.selected }, () => {
			this.handlePaymentId(this.state.userId, selectedDataPay, this.state.paymentSearch);
		});
	};

	handlePaymentId = async (id, page, search) => {};

	handleKycProcess = async (userId, status) => {
		let response = await getKycProcess(userId, status);
		if (response.status === 1) {
			toast.success(response.msg);
			this.handleCallApi();
		} else {
			toast.error(response.msg);
		}
	};

	handleSuspendUser = async (id) => {
		let response = await getSuspendUser(id, 2);
		if (response.status === 1) {
			toast.success("Account Suspended");
			this.handleCallApi();
		} else {
			toast.error(response.message);
		}
	};

	handleActivateUser = async (id) => {
		let response = await getSuspendUser(id, 1);
		if (response.status === 1) {
			toast.success(response.message);
			this.handleCallApi();
		} else {
			toast.error(response.message);
		}
	};

	getKYCStatus = (status) => {
		if (status === 0) {
			return <span className='live text-primary overline'>Not Uploaded</span>;
		} else if (status === 1) {
			return <span className='live text-warning overline'>Verification Pending</span>;
		} else if (status === 2) {
			return <span className='live text-success overline'>KYC Approved</span>;
		} else if (status === 3) {
			return <span className='live text-danger overline'>Document Rejected</span>;
		} else if (status === 4) {
			return <span className='live text-warning overline'>Pan Only</span>;
		}
	};

	validation = () => {
		let { errorIs, comment } = this.state;
		errorIs.comment = !comment.length ? "Required Field " : "";
		this.setState({
			errorIs,
		});
		return !errorIs.comment;
	};

	validateForm = (name, value) => {
		let { errorIs } = this.state;
		switch (name) {
			case "comment":
				errorIs.comment = !value.length ? "Required Field " : "";
				break;
			default:
				break;
		}
		this.setState({ errorIs });
		return !errorIs.comment;
	};

	onClickChange = () => {
		const { errorIs, errorAre, selectType } = this.state;
		errorIs.comment = "";
		errorAre.amountPaid = "";
		errorAre.commentRefund = "";
		errorAre.selectContest = "";
		this.setState({
			errorIs,
			comment: "",
			errorAre,
			amountPaid: "",
			commentRefund: "",
			selectContest: "",
			amountRefund: selectType.label === "Refund" ? 0 : "",
			selectType: { value: "", label: "Refund" },
		});
	};

	onClickChangeAll = () => {
		this.onClickChange();
		this.handlePastContestListForRefund(this.state.userId);
	};

	handleRejectTextReason = (e) => {
		e.preventDefault();
		this.setState({ comment: e.target.value }, () => {
			this.validateForm("comment", this.state.comment);
		});
	};

	handleRefundTextReason = (e) => {
		e.preventDefault();
		this.setState({ [e.target.name]: e.target.value });
		this.validationRefund(e.target.name, e.target.value);
	};

	handleRejectStatus = async () => {
		const { comment, userId, kycStatus } = this.state;
		if (this.validation()) {
			let response = await getKycProcessReject(userId, kycStatus, comment);
			if (response.status === 1) {
				this.setState({ comment: "" }, () => {
					toast.success(response.msg);
					this.handleCallApi();
				});
			} else {
				toast.error(response.msg);
			}
		}
	};

	getWalletType = (status, id) => {
		if (id === 1) {
			return <span className='badge badge-soft-success'>Loan</span>;
		} else if (id === 2) {
			return <span className='badge badge-soft-success'>Payback</span>;
		} else if (id === 3) {
			return <span className='badge badge-soft-success'>Referral Bonus</span>;
		} else {
			if (status === 1) {
				return <span className='badge badge-soft-success'>Bonus</span>;
			} else if (status === 2) {
				return <span className='badge badge-soft-success'>Winning</span>;
			} else if (status === 3) {
				return <span className='badge badge-soft-success'>Cash</span>;
			} else {
				return <span className='badge badge-soft-success'>Referral</span>;
			}
		}
	};

	getTxnType = (type) => {
		if (type) {
			return <span className='live text-success overline'>Credit</span>;
		} else return <span className='live text-danger overline'>Debit</span>;
	};

	getTxnStatus = (status) => {
		if (status) {
			return <span className='live text-success overline'>Success</span>;
		} else return <span className='live text-primary overline'>Pending</span>;
	};

	handleCallApi = () => {
		this.handleUserListById(this.state.userId);
		this.handleEnterContestListById(this.state.userId, this.state.currentPage, this.state.enterContestSearch);
		this.handlePastContestListById(this.state.userId, this.state.currentPagePast, this.state.pastContestSearch);
		this.handleWithdrawList(this.state.userId, this.state.currentPagewithdraw, this.state.withdrawSearch);
	};

	redirectViewContest = (contestId) => {
		if (contestId) {
			this.props.history.push(`/Viewcontest?contestId=${contestId}`);
		}
	};

	handleRanks = (name, ranks, index) => {
		const { userListData } = this.state;

		let filtered = ranks?.filter((item) => item.customer === userListData[0]?.customer);
		let object = [];

		if (filtered.length === 1) {
			object = filtered[0];
		} else {
			object = filtered.sort((a, b) => parseInt(b.rank) - parseInt(a.rank))[0];
		}

		if (name === "rank") {
			return object?.rank === undefined ? "-" : object.rank;
		} else {
			return object?.price === undefined ? "-" : object.price;
		}
	};

	getContestStatus(obj) {
		let startTime = new Date(obj.startTime);
		let endTime = new Date(obj.endTime);
		let timeNow = new Date();

		let current_time = moment(new Date()).format("DD/MM/YYYY  HH:mm:ss");
		let start_time = moment(obj.startTime).format("DD/MM/YYYY  HH:mm:ss");
		let startWithCurrentDiff = moment(start_time, "DD/MM/YYYY HH:mm:ss").diff(
			moment(current_time, "DD/MM/YYYY HH:mm:ss")
		);

		if (!obj.contest_status && startWithCurrentDiff <= 0) {
			return <span className='live text-orange'>Cancelled</span>;
		} else if (endTime < timeNow) {
			return <span className='live text-orange'>Contests Ended</span>;
		} else if (timeNow < endTime && timeNow > startTime) {
			return (
				<span className='live text-orange'>
					<span className='dot'></span>Live
				</span>
			);
		} else {
			let diff = this.getHumanizedDiff(startTime - timeNow);
			return <span className='live text-orange'>{diff}</span>;
		}
	}

	validationRefund(name, value) {
		const { errorAre } = this.state;
		switch (name) {
			case "amountPaid":
				errorAre.amountPaid = !value.length ? "Required Field" : "";
				break;
			case "commentRefund":
				errorAre.commentRefund = !value.length ? "Required Field" : "";
				break;
			case "selectContest":
				errorAre.selectContest = value?.value
					? !value.value.toString().length
						? "Required Field"
						: ""
					: "Required Field";
				break;
			default:
				break;
		}
	}

	validateFormRefund() {
		const { errorAre, commentRefund, amountPaid, selectType, selectContest } = this.state;
		if (selectType.label === "Refund") {
			errorAre.commentRefund = !commentRefund.length ? "Required Field" : "";
			errorAre.selectContest = selectContest?.value
				? !selectContest.value.toString().length
					? "Required Field"
					: ""
				: "Required Field";
		} else {
			errorAre.commentRefund = !commentRefund.length ? "Required Field" : "";
			errorAre.amountPaid = !amountPaid.length ? "Required Field" : "";
		}
		this.setState({ errorAre });
		return !errorAre.commentRefund && !errorAre.amountPaid && !errorAre.selectContest;
	}

	handleRefundBonus = async () => {
		const { commentRefund, amountPaid, selectType, ContestValue, userId } = this.state;
		if (this.validateFormRefund()) {
			let data = {};

			if (selectType.label === "Refund") {
				data = {
					contestId: ContestValue,
					userId: userId,
					money: "",
					type: "",
					reason: commentRefund,
				};
			} else {
				data = {
					contestId: "",
					userId: userId,
					money: amountPaid,
					type: "addmoney",
					reason: commentRefund,
				};
			}
			let response = await addMoneyOrRefund(data);
			if (response.status === 0) {
				toast.success(response.msg);
				this.handleCallApi();
			} else {
				toast.error(response.msg);
			}
		}
	};

	handleChange = (selectedOption) => {
		this.setState({
			documentType: selectedOption,
		});
	};

	handleChangeContest = (selectedOption) => {
		let result = selectedOption.value.split("=");
		let resultId = result?.[0] ? result[0] : "";
		let resultAmount = result?.[1] ? result[1] : "";
		this.setState({
			selectContest: selectedOption,
			ContestValue: resultId,
			amountRefund: resultAmount,
		});
		this.validationRefund("selectContest", selectedOption);
	};

	getExperience = (value) => {
		if (value === 1) {
			return "0-1 year";
		} else if (value === 2) {
			return "0-5 year";
		} else if (value === 3) {
			return "5-10 year";
		} else if (value === 4) {
			return "10+ year";
		}
	};
	componentDidMount() {
		// for vidro light

		const section = document.querySelector("section"),
			mainVideo = document.querySelector(".main-video video"),
			videos = document.querySelectorAll(".videos"),
			close = document.querySelector(".close");

		for (var i = 0; i < videos.length; i++) {
			videos[i].addEventListener("click", (e) => {
				const target = e.target;
				section.classList.add("active");
				target.classList.add("active");
				let src = document.querySelector(".videos.active video").src;
				mainVideo.src = src;

				close.addEventListener("click", () => {
					section.classList.remove("active");
					target.classList.remove("active");
					mainVideo.src = "";
				});
			});
		}

		let userId = this.props?.match?.params?.userid;

		let current_time = moment(new Date()).format("DD/MM/YYYY  HH:mm:ss");
		if (userId) {
			this.setState(
				{
					userId: userId,
					LoadingIs: true,
					LoadingPast: true,
					LoadingWithdraw: true,
					currentTime: current_time,
				},
				() => {
					this.handleCallApi();
				}
			);
		}
		//  this.setState({
		//   enterContestcombo:[...this.state.enteredContestList,...this.state.enterContestlive]

		//  })
	}

	render() {
		const {
			userListData,
			maritalStatus,
			LoadingIs,
			LoadingPast,
			enteredContestList,
			enterContestSearch,
			pastContestSearch,
			loadingAre,
			contestDetailData,
			pastContestList,
			errorIs,
			LoadingWithdraw,
			withdrawList,
			withdrawSearch,
			indexCount,
			indexCountPast,
			contestList,
			selectType,
			errorAre,
		} = this.state;
		const curTime = new Date();

		return (
			<>
				<Navbar />

				<SimpleReactLightbox>
					<div className='main-content'>
						<div className='header'>
							<div className='container-fluid'>
								<div className='header-body'>
									<div className='row align-items-end'>
										<div className='col'>
											<h6 className='header-pretitle'> User </h6>
											<h1 className='header-title'> Detail</h1>
										</div>
										{this.props.userRole === 2 ? (
											<div className='col-auto'>
												<button
													className='btn btn-sm btn-primary mr-1'
													data-toggle='modal'
													data-target='#refundOrAddMoney'
													onClick={this.onClickChangeAll}>
													Refund
												</button>
											</div>
										) : (
											""
										)}
										{this.props.userRole === 2 ? (
											<div className='col-auto'>
												{userListData[0]?.suspended ? (
													<button
														className='btn btn-sm btn-primary mr-1'
														onClick={() => this.handleActivateUser(this.state.userId)}>
														Activate user
													</button>
												) : (
													<button
														className='btn btn-sm btn-primary mr-1'
														data-toggle='modal'
														data-target='#suspendUser'>
														Suspend
													</button>
												)}
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>

						<div className='container topInfo'>
							<div className='inline'>
								<h1 style={{ textTransform: "capitalize" }}>
									{console.log(userListData?.usersProfile?.firstName)}
									{userListData?.usersProfile?.firstName} {userListData?.usersProfile?.lastName}
								</h1>
								<p>{userListData?.usersProfile?.gender}</p>
							</div>
							<div className='inline float-right'>
								<span className='fe fe-map-pin fs-3 text-primary inline'></span>{" "}
								<p className='inline location'>13th Street, NY, 10011, USA</p>
							</div>
							<LightBox />
						</div>

						<div className='container'>
							<div className='row'>
								<div className='card col-md-12 personalInfo '>
									<h4 className='card-header-title pt-3 my-3 mx-4'>Personal Info</h4>
									<hr />
									<div className='row mx-3 my-3 '>
										<div className='col-md-12 brdr-btm'>
											<div className='form-group pd-1rem' style={{ paddingTop: "0px" }}>
												<label className='textPink' style={{ fontSize: "22px", fontWeight: "600" }}>
													
													Bio
												</label>
												<div
													className=''
													style={{
														textAlign: "justify",
													}}>
													Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
													been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
													galley of type and scrambled it to make a type specimen book. It has survived not only five
													centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It
													was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
													passages, and more recently with desktop publishing software like Aldus PageMaker including
													versions of Lorem Ipsum.
												</div>
											</div>
										</div>
										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'> User Id </label>
												<div className=''>{userListData?.usersProfile?.id ?userListData?.usersProfile?.id:"-"}</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Phone </label>
												<div className=''>
													{userListData?.usersProfile?.phoneNumber ? userListData?.usersProfile?.phoneNumber : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Sexuality</label>
												<div className=''>
													{userListData?.usersProfile?.sexuality ? userListData?.usersProfile?.sexuality : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Pronouns</label>
												<div className=''>
													{userListData?.usersProfile?.pronouns ? userListData?.usersProfile?.pronouns : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Date of birth</label>
												<div className=''>
													{userListData?.usersProfile?.dateOfBirth ? userListData?.usersProfile?.dateOfBirth : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Age</label>
												<div className=''>
													{userListData?.usersProfile?.age ? userListData?.usersProfile?.age : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 '>
											<div className='form-group pd-1rem'>
												<label className='textPink'> marital status</label>
												<div className=''>
													{userListData?.usersProfile?.maritalStatus
														? userListData?.usersProfile?.maritalStatus
														: "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 '>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Children</label>
												<div className=''>
													{userListData?.usersProfile?.children ? userListData?.usersProfile?.children : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-4 '>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Pets</label>
												<div className=''>
													{userListData?.usersProfile?.pets ? userListData?.usersProfile?.pets : "NA"}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='card col-md-12 lifeStyle'>
									<h4 className='card-header-title pt-3 my-3 mx-4'>Lifestyle</h4>
									<hr />
									<div className='row my-3 mx-3'>
										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Hobbies</label>
												<div className=''>
													{userListData?.usersLifestyle?.Hobbies ? userListData?.usersLifestyle?.Hobbies : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Diet</label>
												<div className=''>
													{userListData?.usersLifestyle?.Diet ? userListData?.usersLifestyle?.Diet : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Height</label>
												<div className=''>
													{userListData?.usersLifestyle?.Height
														? (userListData?.usersLifestyle?.Height*0.032808).toFixed(1).split(".").join("'") + "''"
														: "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Body Type</label>
												<div className=''>
													{userListData?.usersLifestyle?.BodyType ? userListData?.usersLifestyle?.BodyType : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Excercise</label>
												<div className=''>
													{userListData?.usersLifestyle?.Exercise ? userListData?.usersLifestyle?.Exercise : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Smoking Habits</label>
												<div className=''>
													{userListData?.usersLifestyle?.SmokingHabits
														? userListData?.usersLifestyle?.SmokingHabits
														: "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Drinking Habits</label>
												<div className=''>
													{userListData?.usersLifestyle?.DrinkingHabits
														? userListData?.usersLifestyle?.DrinkingHabits
														: "NA"}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='card col-md-12 career '>
									<h4 className='card-header-title pt-3 my-3 mx-4'>Career</h4>
									<hr />
									<div className='row mx-3 my-3'>
										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>University/College</label>
												<div className=''>
													{userListData?.usersCareer?.University ? userListData?.usersCareer?.University : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Industry</label>
												<div className=''>
													{userListData?.usersCareer?.Industry ? userListData?.usersCareer?.Industry : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Language</label>
												<div className=''>
													{userListData?.usersCareer?.Language ? userListData?.usersCareer?.Language : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Vocational Training </label>
												<div className=''>
													{userListData?.usersCareer?.VocationalTraining
														? userListData?.usersCareer?.VocationalTraining
														: "NA"}
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* <div className='card col-md-12 kyc'>
                  <div className='form-group'>
                    <h4 className=' my-4 mx-5 card-header-title '>
                      {" "}
                      KYC Documents{" "}
                      {this.getKYCStatus(userListData[0]?.documentStatus)}
                     
                    </h4>
                    <hr />
                    <SRLWrapper>
                      <ul className='pl-0'>
                        <div className='row'>
                          {
                            <>
                              {this.state.aadhaarImage?.length ? (
                                <div className='row'>
                                  <div className='d-flex flex-row'>
                                    <div className='card-body py-4 px-3 col-md-3 d-flex justify-content-center align-self-center card-title'>
                                      <div className='row '>
                                        <h5 className='mb-0 '>
                                          Aadhaar Card Photo
                                        </h5>
                                      </div>
                                    </div>
                                    <div className='col-md-2 mr-4 '>
                                      <div className='my-2 h-50 w-100'>
                                        <img
                                          src={`http://43.204.94.42:3001/${this.state.aadhaarImage}`}
                                          className='card-img-top  rounded'
                                          alt='...'
                                        />
                                      </div>
                                    </div>

                                    {console.log(
                                      this.state.verifyadhar,
                                      this.state.activeadhar,
                                      "2222222"
                                    )}
                                    {console.log(
                                      this.state.verifypen,
                                      this.state.activepen,
                                      "111111"
                                    )}
                                    {this.state.verifyadhar &&
                                    this.state.activeadhar ? (
                                      <>
                                        <div className='text-center col-md-2 align-self-center'>
                                          <div
                                            className='btn-group'
                                            role='group'
                                          >
                                            <button className='btn-success rounded'>
                                              Accepted
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : !this.state.verifyadhar &&
                                      !this.state.activeadhar ? (
                                      <div className='text-center col-md-2 align-self-center'>
                                        <div className='btn-group' role='group'>
                                          <button className='btn-danger rounded'>
                                            Rejected
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className='col-md-3 align-self-center'>
                                          <Select
                                            onChange={this.handleChange}
                                            options={action}
                                            value={this.state.selectStatus}
                                          />
                                        </div>

                                        <div className='text-center col-md-2 align-self-center'>
                                          <div
                                            className='btn-group'
                                            role='group'
                                          >
                                            <button
                                              type='button'
                                              className='btn btn-success rounded'
                                              onClick={() => this.handleKyc(1)}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.panCardImage?.length ? (
                                <div className='row'>
                                  <div className='d-flex flex-row'>
                                    <div className='card-body py-4 px-3 col-md-3 d-flex justify-content-center align-self-center card-title'>
                                      <div className='row align-items-center'>
                                        <h5 className=' card-title'>
                                          Pan Card Photo
                                        </h5>
                                      </div>
                                    </div>
                                    <div className='col-md-2 mr-4'>
                                      <div className='my-2 h-50 w-100'>
                                        <img
                                          src={`http://43.204.94.42:3001/${this.state.panCardImage}`}
                                          className='card-img-top rounded'
                                          alt='...'
                                        />
                                      </div>
                                    </div>
                                   
                                    {this.state.verifypen &&
                                    this.state.activepen ? (
                                      <>
                                        <div className='text-center col-md-2 align-self-center'>
                                          <div
                                            className='btn-group'
                                            role='group'
                                          >
                                            <button className='btn-success rounded'>
                                              Accepted
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : !this.state.verifypen &&
                                      !this.state.activepen ? (
                                      <>
                                        <div className='text-center col-md-2 align-self-center'>
                                          <div
                                            className='btn-group'
                                            role='group'
                                          >
                                            <button className='btn-danger rounded'>
                                              Rejected
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className='col-md-3 align-self-center'>
                                          <Select
                                            onChange={this.handleChange}
                                            options={action}
                                            value={this.state.selectStatus}
                                          />
                                        </div>

                                        <div className='text-center col-md-2 align-self-center'>
                                          <div
                                            className='btn-group'
                                            role='group'
                                          >
                                            <button
                                              type='button'
                                              className='btn btn-success rounded'
                                              onClick={() => this.handleKyc(2)}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          }
                        </div>
                      </ul>
                    </SRLWrapper>
                  </div>
                </div> */}

								{/* <div className='card col-md-12 bankDtls'>
                  <h4 className='card-header-title pt-3 my-3 mx-4'>
                    Bank Details
                  </h4>
                  <hr />
                  <div className='row ml-4 my-3'>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label className='textPink'>Bank Name </label>
                        <div className=''>{`${userListData[0]?.firstName} ${userListData[0]?.lastName}`}</div>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label className='textPink'>Holder Name </label>
                        <div className=''>
                          {userListData[0]?.phoneNumber
                            ? userListData[0]?.phoneNumber
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label className='textPink'>Account Number </label>
                        <div className=''>{userListData[0]?.email}</div>
                      </div>
                    </div>

                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label className='textPink'>IFSC Code </label>
                        <div className=''>{userListData[0]?.email}</div>
                      </div>
                    </div>
                   
                  </div>
                </div> */}

								{/* <div className='col-md-12 mx-0 enterCon '>
                  <div className='card'>
                    <div className='card-header'>
                      <h4 className='card-header-title'> Entered Contests</h4>
                      <form>
                        <div className='input-group input-group-flush input-group-merge'>
                          <input
                            type='search'
                            className='form-control form-control-prepended search'
                            onChange={this.handleEnterContestSearch}
                            value={enterContestSearch}
                            placeholder='Search'
                          />
                          <div className='input-group-prepend'>
                            <div className='input-group-text'>
                              <span className='fe fe-search' />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className='table-responsive mb-0 max-height'>
                      <table className='table table-hover table-sm card-table '>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("contest_name")}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              Contest name
                            </th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("asset_type")}
                              style={{ cursor: "pointer" }}
                            >
                              Type of contests
                            </th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("entryFee")}
                              style={{ cursor: "pointer" }}
                            >
                              entry fees
                            </th>
                            <th
                              colSpan='2'
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("startTime")}
                              style={{ cursor: "pointer" }}
                            >
                              Status of contests
                            </th>
                          </tr>
                        </thead>

                        {LoadingIs ? (
                          <tbody>
                            <tr>
                              <td colSpan='6'>
                                <div className='spinner-border' role='status'>
                                  <span className='sr-only'>Loading...</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {enteredContestList &&
                            enteredContestList.length > 0 ? (
                              enteredContestList.map((obj, index) => (
                                <tr
                                  className='cursor-pointer'
                                  data-toggle='modal'
                                  data-target='#demo-modal'
                                  onClick={() =>
                                    this.handleContestDetail(
                                      obj.userId,
                                      obj.id,
                                      true
                                    )
                                  }
                                >
                                  <td>{indexCount * 50 + (index + 1)}</td>
                                  <td>
                                    <Link
                                      to={
                                        obj?.contestId
                                          ? {
                                              pathname: "/Viewcontest",
                                              search: `contestId=${obj.id}`,
                                            }
                                          : "#"
                                      }
                                      className='display-content'
                                    >
                                      {obj.contestName}
                                    </Link>
                                  </td>
                                  <td>
                                    {obj.contest_type}{" "}
                                    {obj.contest_fee_type === 1
                                      ? `Stocks`
                                      : obj.asset_type === 2
                                      ? `Crypto`
                                      : null}
                                  </td>
                                  <td>
                                    <span className='rupees_icon'>
                                      <svg
                                        version='1.1'
                                        id='Capa_1'
                                        xmlns='http://www.w3.org/2000/svg'
                                        xmlnsXlink='http://www.w3.org/1999/xlink'
                                        x='0px'
                                        y='0px'
                                        width='401.998px'
                                        height='401.998px'
                                        viewBox='0 0 401.998 401.998'
                                        style={{
                                          enableBackground:
                                            "new 0 0 401.998 401.998",
                                        }}
                                        xmlSpace='preserve'
                                      >
                                        <g>
                                          <path
                                            d='M326.62,91.076c-1.711-1.713-3.901-2.568-6.563-2.568h-48.82c-3.238-15.793-9.329-29.502-18.274-41.112h66.52
                                                                        c2.669,0,4.853-0.856,6.57-2.565c1.704-1.712,2.56-3.903,2.56-6.567V9.136c0-2.666-0.855-4.853-2.56-6.567
                                                                        C324.334,0.859,322.15,0,319.481,0H81.941c-2.666,0-4.853,0.859-6.567,2.568c-1.709,1.714-2.568,3.901-2.568,6.567v37.972
                                                                        c0,2.474,0.904,4.615,2.712,6.423s3.949,2.712,6.423,2.712h41.399c40.159,0,65.665,10.751,76.513,32.261H81.941
                                                                        c-2.666,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.901-2.568,6.567v29.124c0,2.664,0.855,4.854,2.568,6.563
                                                                        c1.714,1.715,3.905,2.568,6.567,2.568h121.915c-4.188,15.612-13.944,27.506-29.268,35.691
                                                                        c-15.325,8.186-35.544,12.279-60.67,12.279H81.941c-2.474,0-4.615,0.905-6.423,2.712c-1.809,1.809-2.712,3.951-2.712,6.423v36.263
                                                                        c0,2.478,0.855,4.571,2.568,6.282c36.543,38.828,83.939,93.165,142.182,163.025c1.715,2.286,4.093,3.426,7.139,3.426h55.672
                                                                        c4.001,0,6.763-1.708,8.281-5.141c1.903-3.426,1.53-6.662-1.143-9.708c-55.572-68.143-99.258-119.153-131.045-153.032
                                                                        c32.358-3.806,58.625-14.277,78.802-31.404c20.174-17.129,32.449-39.403,36.83-66.811h47.965c2.662,0,4.853-0.854,6.563-2.568
                                                                        c1.715-1.709,2.573-3.899,2.573-6.563V97.646C329.193,94.977,328.335,92.79,326.62,91.076z'
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                    {obj.entryFee}
                                  </td>
                                  <td>{this.getContestStatus(obj)}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan='5'>No data Found...</td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className='col-12 align-items-center'>
                    {this.state.totalPages > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.totalPages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                  <hr className='my-5' />
                </div> */}

								{/* <div className='col-12 pastCon'>
                  <div className='card'>
                    <div className='card-header'>
                      <h4 className='card-header-title'> Past Contests</h4>
                      <form>
                        <div className='input-group input-group-flush input-group-merge'>
                          <input
                            type='search'
                            className='form-control form-control-prepended search'
                            onChange={this.handlePastContestSearch}
                            value={pastContestSearch}
                            placeholder='Search'
                          />
                          <div className='input-group-prepend'>
                            <div className='input-group-text'>
                              <span className='fe fe-search' />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className='table-responsive mb-0 max-height'>
                      <table className='table table-hover table-sm card-table '>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortByPast("contestName")}
                              style={{ cursor: "pointer" }}
                            >
                              Contest name
                            </th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortByPast("startTime")}
                              style={{ cursor: "pointer" }}
                            >
                              Contest Date
                            </th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortByPast("asset_type")}
                              style={{ cursor: "pointer" }}
                            >
                              Type of contests
                            </th>
                            <th
                              className='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortByPast("entryFee")}
                              style={{ cursor: "pointer" }}
                            >
                              entry fees
                            </th>
                            <th
                              className='textPink'
                              data-sort='tables-row'
                              onClick={() => this.sortByPast("startTime")}
                              style={{ cursor: "pointer" }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>

                        {LoadingPast ? (
                          <tbody>
                            <tr>
                              <td colSpan='6'>
                                <div className='spinner-border' role='status'>
                                  <span className='sr-only'>Loading...</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {pastContestList && pastContestList.length > 0 ? (
                              pastContestList.map((obj, index) => (
                                <tr
                                  className='cursor-pointer'
                                  data-toggle='modal'
                                  data-target='#demo-modal'
                                  onClick={() =>
                                    this.handleContestDetail(
                                      this.state.userId,
                                      obj.contestId,
                                      false
                                    )
                                  }
                                >
                                  <td>{indexCountPast * 50 + (index + 1)}</td>
                                  <td>
                                    <Link
                                      to={
                                        obj?.contestId
                                          ? {
                                              pathname: "/Viewcontest",
                                              search: `contestId=${obj.contestId}`,
                                            }
                                          : "#"
                                      }
                                      className='display-content'
                                    >
                                      {obj.contestName}
                                    </Link>
                                  </td>
                                  <td>
                                    {moment(obj?.startTime).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {obj.contest_type}{" "}
                                    {obj.asset_type === 1 ? "Stock" : "Crypto"}
                                  </td>
                                  <td>
                                    <span className='rupees_icon'>
                                      <svg
                                        version='1.1'
                                        id='Capa_1'
                                        xmlns='http://www.w3.org/2000/svg'
                                        xmlnsXlink='http://www.w3.org/1999/xlink'
                                        x='0px'
                                        y='0px'
                                        width='401.998px'
                                        height='401.998px'
                                        viewBox='0 0 401.998 401.998'
                                        style={{
                                          enableBackground:
                                            "new 0 0 401.998 401.998",
                                        }}
                                        xmlSpace='preserve'
                                      >
                                        <g>
                                          <path
                                            d='M326.62,91.076c-1.711-1.713-3.901-2.568-6.563-2.568h-48.82c-3.238-15.793-9.329-29.502-18.274-41.112h66.52
                                                                                    c2.669,0,4.853-0.856,6.57-2.565c1.704-1.712,2.56-3.903,2.56-6.567V9.136c0-2.666-0.855-4.853-2.56-6.567
                                                                                    C324.334,0.859,322.15,0,319.481,0H81.941c-2.666,0-4.853,0.859-6.567,2.568c-1.709,1.714-2.568,3.901-2.568,6.567v37.972
                                                                                    c0,2.474,0.904,4.615,2.712,6.423s3.949,2.712,6.423,2.712h41.399c40.159,0,65.665,10.751,76.513,32.261H81.941
                                                                                    c-2.666,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.901-2.568,6.567v29.124c0,2.664,0.855,4.854,2.568,6.563
                                                                                    c1.714,1.715,3.905,2.568,6.567,2.568h121.915c-4.188,15.612-13.944,27.506-29.268,35.691
                                                                                    c-15.325,8.186-35.544,12.279-60.67,12.279H81.941c-2.474,0-4.615,0.905-6.423,2.712c-1.809,1.809-2.712,3.951-2.712,6.423v36.263
                                                                                    c0,2.478,0.855,4.571,2.568,6.282c36.543,38.828,83.939,93.165,142.182,163.025c1.715,2.286,4.093,3.426,7.139,3.426h55.672
                                                                                    c4.001,0,6.763-1.708,8.281-5.141c1.903-3.426,1.53-6.662-1.143-9.708c-55.572-68.143-99.258-119.153-131.045-153.032
                                                                                    c32.358-3.806,58.625-14.277,78.802-31.404c20.174-17.129,32.449-39.403,36.83-66.811h47.965c2.662,0,4.853-0.854,6.563-2.568
                                                                                    c1.715-1.709,2.573-3.899,2.573-6.563V97.646C329.193,94.977,328.335,92.79,326.62,91.076z'
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                    {obj.entryFee}
                                  </td>
                                  <td>{this.getContestStatus(obj)}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan='6'>No data Found...</td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className='col-12 align-items-center'>
                    {this.state.totalPagesIs > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.totalPagesIs}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClickIs}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                </div> */}
							</div>
						</div>
						<ToastContainer position='top-center' autoClose={2000} />
					</div>
				</SimpleReactLightbox>
				{/* ===========modal close================ */}

				<RightSidebar
					amount={this.state.modalAmount}
					status={this.state.modalStatus}
					comments={this.state.modalComments}
					renderFunction={() => this.handleCallApi()}
				/>

				<div className='modal fade fixed-right ' id='demo-modal' tabIndex={-1} role='dialog' aria-hidden='true'>
					<div className='modal-dialog modal-dialog-vertical' role='document'>
						<div className='modal-content'>
							<div className='modal-body'>
								<a href='/#' className='close' data-dismiss='modal' aria-label='Close'>
									<span aria-hidden='true' style={{ cursor: "pointer" }}>
										×
									</span>
								</a>
								<div className='header'>
									<div className='header-body'>
										<div className='row align-items-end'>
											<div className='col'>
												<h6 className='header-pretitle'>Overview</h6>
												<h1 className='header-title'> Number of Portfolio</h1>
											</div>
											{this.state.showRefresh ? (
												<div className='col ml-auto text-right'>
													<i
														className='fe fe-refresh-ccw mr-2'
														style={{
															fontWeight: "bold",
															fontSize: "1.5rem",
															cursor: "pointer",
														}}
														onClick={() =>
															this.handleContestDetail(this.state.portUserId, this.state.portContestId, true)
														}
													/>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>

								<div className='accordions' id='accordionExample'>
									{loadingAre ? (
										<div className='card'>
											<div className='spinner-border' role='status'>
												<span className='sr-only'>Loading...</span>
											</div>
										</div>
									) : contestDetailData && contestDetailData.length > 0 ? (
										contestDetailData.map((obj, index) => (
											<div className='card'>
												<div className='card-header  border-bottom-0' id={`handle${obj.id}`}>
													<div
														className='d-flex justify-content-between'
														data-toggle='collapse'
														data-target={`#collapse${obj.id}`}>
														<span className='mb-0' style={{ cursor: "pointer" }}>
															Portfolio {obj.portofolio_number} <i className='fe fe-chevron-down ml-2'></i>
														</span>
														{this.state.chechPrize === 2 ? <span>Rank {obj.rank} </span> : ""}
														{this.state.chechPrize === 2 ? (
															<span>
																Prize{" "}
																<span className='rupees_icon'>
																	<svg
																		version='1.1'
																		id='Capa_1'
																		xmlns='http://www.w3.org/2000/svg'
																		xmlnsXlink='http://www.w3.org/1999/xlink'
																		x='0px'
																		y='0px'
																		width='401.998px'
																		height='401.998px'
																		viewBox='0 0 401.998 401.998'
																		style={{
																			enableBackground: "new 0 0 401.998 401.998",
																		}}
																		xmlSpace='preserve'>
																		<g>
																			<path
																				d='M326.62,91.076c-1.711-1.713-3.901-2.568-6.563-2.568h-48.82c-3.238-15.793-9.329-29.502-18.274-41.112h66.52
                                            c2.669,0,4.853-0.856,6.57-2.565c1.704-1.712,2.56-3.903,2.56-6.567V9.136c0-2.666-0.855-4.853-2.56-6.567
                                            C324.334,0.859,322.15,0,319.481,0H81.941c-2.666,0-4.853,0.859-6.567,2.568c-1.709,1.714-2.568,3.901-2.568,6.567v37.972
                                            c0,2.474,0.904,4.615,2.712,6.423s3.949,2.712,6.423,2.712h41.399c40.159,0,65.665,10.751,76.513,32.261H81.941
                                            c-2.666,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.901-2.568,6.567v29.124c0,2.664,0.855,4.854,2.568,6.563
                                            c1.714,1.715,3.905,2.568,6.567,2.568h121.915c-4.188,15.612-13.944,27.506-29.268,35.691
                                            c-15.325,8.186-35.544,12.279-60.67,12.279H81.941c-2.474,0-4.615,0.905-6.423,2.712c-1.809,1.809-2.712,3.951-2.712,6.423v36.263
                                            c0,2.478,0.855,4.571,2.568,6.282c36.543,38.828,83.939,93.165,142.182,163.025c1.715,2.286,4.093,3.426,7.139,3.426h55.672
                                            c4.001,0,6.763-1.708,8.281-5.141c1.903-3.426,1.53-6.662-1.143-9.708c-55.572-68.143-99.258-119.153-131.045-153.032
                                            c32.358-3.806,58.625-14.277,78.802-31.404c20.174-17.129,32.449-39.403,36.83-66.811h47.965c2.662,0,4.853-0.854,6.563-2.568
                                            c1.715-1.709,2.573-3.899,2.573-6.563V97.646C329.193,94.977,328.335,92.79,326.62,91.076z'
																			/>
																		</g>
																	</svg>
																</span>
																{obj.price}{" "}
															</span>
														) : (
															""
														)}
														<span
															className={`badge badge-soft-${
																this.handleTotalPortfolioPoints(obj.stocks) > 0
																	? `success`
																	: this.handleTotalPortfolioPoints(obj.stocks) === 0
																	? `primary`
																	: `danger`
															}`}
															style={{ cursor: "pointer" }}>
															{this.handleTotalPortfolioPoints(obj.stocks)} points
														</span>
													</div>
												</div>
												<div id={`collapse${obj.id}`} className='collapse show' aria-labelledby={`handle${obj.id}`}>
													<div className='table-responsive mb-0'>
														<table className='table table-sm table-hover card-table'>
															<tbody>
																{obj.stocks && obj.stocks.length > 0 ? (
																	obj.stocks.map((value, index) => (
																		<tr className=''>
																			<td> {value.companyName} </td>
																			<td>{parseFloat(value.lastPrice).toFixed(2)}</td>
																			<td className='text-right d-flex justify-content-between'>
																				{" "}
																				<span
																					className={`badge ${
																						value.points > 0
																							? "badge-soft-success"
																							: value.points === 0
																							? "badge-soft-primary"
																							: "badge-soft-danger"
																					}`}>
																					{parseFloat(value.points).toFixed(2)} pts
																				</span>
																				{value.gain_priority === 1 ? (
																					<span className='badge badge-soft-success ml-4'>2X</span>
																				) : value.gain_priority === 2 ? (
																					<span className='badge badge-soft-success ml-4'>1.5X</span>
																				) : null}
																				{value.isActive ? (
																					""
																				) : (
																					<span className='badge badge-soft-warning ml-4'>Exited</span>
																				)}
																			</td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td>No Data Found ...</td>
																	</tr>
																)}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										))
									) : (
										<div
											className='card'
											style={{
												paddingLeft: "1.5rem",
												paddingTop: "1rem",
												paddingBottom: "1rem",
											}}>
											No Data Found ...
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* ================ accept============== */}

				<div
					className='modal fade adddoctor'
					id='kycProcess'
					tab-index='-1'
					role='dialog'
					aria-labelledby='#kycProcess'
					aria-hidden='true'>
					<div className='modal-dialog modal-dialog-centered' role='document'>
						<div className='modal-content'>
							<div className='modal-card card'>
								<div className='card-body'>
									<div className='col-12 col-sm-12 col-md-12 col-lg-12'>
										<div
											className='form-group'
											style={{
												textAlign: "center",
												fontWeight: "bold",
												fontSize: "1.3rem",
											}}>
											<label>Are You Sure You Want To Approved This KYC</label>
										</div>
										<hr className='my-4 ' />
									</div>

									<div className='col-12 text-center popupbuttonfield'>
										<button
											className='btn btn-primary lift'
											style={{ marginRight: ".5rem", width: "5rem" }}
											data-dismiss='modal'
											onClick={() => this.handleKycProcess(this.state.userId, this.state.kycStatus)}>
											Yes
										</button>
										<button
											className='btn btn-primary lift'
											style={{ marginLeft: ".5rem", width: "5rem" }}
											data-dismiss='modal'
											onClick={() => this.setState({ kycStatus: 1 })}>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* ================ reject============== */}

				<div
					className='modal fade adddoctor'
					id='kycReject'
					tab-index='-1'
					role='dialog'
					aria-labelledby='#kycReject'
					aria-hidden='true'>
					<div className='modal-dialog modal-dialog-centered'>
						<div className='modal-content'>
							<div className='modal-header d-flex align-items-center'>
								<h2 className='modal-title' id='exampleModalLabel'>
									Reject KYC
								</h2>
								<button
									type='button'
									className='close'
									data-dismiss='modal'
									aria-label='Close'
									onClick={this.onClickChange}>
									<span style={{ color: "#000" }} aria-hidden='true'>
										×
									</span>
								</button>
							</div>
							<div className='modal-body'>
								<div className='form-group'>
									<label for='transaction_id'>Reason</label>
									<textarea
										style={{ borderColor: errorIs.comment ? "red" : "" }}
										placeholder='Leave your comment'
										value={this.state.comment}
										onChange={(e) => this.handleRejectTextReason(e)}
										className='form-control'
										rows='5'></textarea>
									{errorIs.comment && <p style={{ color: "red" }}>Required Field</p>}
								</div>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-primary'
									data-dismiss={this.state.comment.length ? "modal" : ""}
									onClick={this.handleRejectStatus}>
									Reject
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					className='modal fade adddoctor'
					id='refundOrAddMoney'
					tab-index='-1'
					role='dialog'
					aria-labelledby='#refundOrAddMoney'
					aria-hidden='true'>
					<div className='modal-dialog modal-dialog-centered' role='document'>
						<div className='modal-content'>
							<div className='modal-header d-flex align-items-center'>
								<h2 className='modal-title' id='exampleModalLabel'>
									Refund
								</h2>
								<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
							<div className='modal-card card'>
								<div className='card-body' style={{ minHeight: 500 }}>
									<div className='col-12 col-sm-12 col-md-12 col-lg-12'>
										<label for='Type'>Type</label>
										<div className='form-group'>
											<Select onChange={this.handleChange} options={types} value={selectType} />
										</div>
									</div>
									{selectType.label === "Refund" ? (
										<div className='col-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
											<div className='form-group'>
												<label for='Contest'>Contest List</label>
												<Select
													height='10rem'
													onChange={this.handleChangeContest}
													options={contestList}
													value={this.state.selectContest}
												/>
												{errorAre.selectContest && <p style={{ color: "red" }}>Required Field</p>}
											</div>
										</div>
									) : (
										""
									)}
									<div className='col-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
										<div className='form-group'>
											<label for='Amount'>Amount</label>
											{selectType.label === "Refund" ? (
												<label
													for='Amount_label'
													style={{
														minHeight: "39px",
														border: "1px solid #E3EBF6",
														width: "100%",
														borderRadius: "0.375rem",
														padding: "5px 8px",
														marginBottom: 0,
														color: "hsl(0, 0%, 50%)",
													}}>
													{this.state.amountRefund}
												</label>
											) : (
												<>
													<input
														type='number'
														className={`form-control ${errorAre.amountPaid ? `error` : ``}`}
														onKeyDown={(event) => {
															if (event.keyCode === 69) {
																event.preventDefault();
															}
														}}
														name='amountPaid'
														value={this.state.amountPaid}
														onChange={(e) => this.handleRefundTextReason(e)}
													/>
													{errorAre.amountPaid ? <span className='err-msg'>{errorAre.amountPaid}</span> : null}
												</>
											)}
										</div>
									</div>
									<div className='col-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
										<div className='form-group'>
											<label for='Reason'>Reason</label>
											<textarea
												style={{
													borderColor: errorAre.commentRefund ? "red" : "",
												}}
												placeholder='Leave your comment'
												value={this.state.commentRefund}
												name='commentRefund'
												onChange={(e) => this.handleRefundTextReason(e)}
												className='form-control'
												rows='5'></textarea>
											{errorAre.commentRefund && <p style={{ color: "red" }}>Required Field</p>}
										</div>
									</div>

									<div className='col-12 text-center popupbuttonfield mt-5'>
										{selectType.label === "Refund" ? (
											<button
												className='btn btn-primary lift'
												style={{ marginRight: ".5rem", width: "5rem" }}
												data-dismiss={this.state.commentRefund.length ? "modal" : ""}
												onClick={this.handleRefundBonus}>
												Yes
											</button>
										) : (
											<button
												className='btn btn-primary lift'
												style={{ marginRight: ".5rem", width: "5rem" }}
												data-dismiss={
													this.state.commentRefund.length && this.state.amountPaid.length > 0 ? "modal" : ""
												}
												onClick={this.handleRefundBonus}>
												Yes
											</button>
										)}
										<button
											className='btn btn-primary lift'
											style={{ marginLeft: ".5rem", width: "5rem" }}
											data-dismiss='modal'>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className='modal fade adddoctor'
					id='suspendUser'
					tab-index='-1'
					role='dialog'
					aria-labelledby='#suspendUser'
					aria-hidden='true'>
					<div className='modal-dialog modal-dialog-centered' role='document'>
						<div className='modal-content'>
							<div className='modal-card card'>
								<div className='card-body'>
									<div className='col-12 col-sm-12 col-md-12 col-lg-12'>
										<div
											className='form-group'
											style={{
												textAlign: "center",
												fontWeight: "bold",
												fontSize: "1.3rem",
											}}>
											<label>Are You Sure You Want To Suspend This User ?</label>
										</div>
										<hr className='my-4 ' />
									</div>

									<div className='col-12 text-center popupbuttonfield'>
										<button
											className='btn btn-primary lift'
											style={{ marginRight: ".5rem", width: "5rem" }}
											data-dismiss='modal'
											onClick={() => this.handleSuspendUser(this.state.userId)}>
											Yes
										</button>
										<button
											className='btn btn-primary lift'
											style={{ marginLeft: ".5rem", width: "5rem" }}
											data-dismiss='modal'>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className='modal rightSidebar fade'
					id='offcanvasDemoWallet'
					tabIndex={-1}
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'>
					<div className='modal-dialog modal-dialog-centered' style={{ maxWidth: 1290 }}>
						<div className='modal-content'>
							<div className='modal-header d-flex align-items-center'>
								<h2 className='modal-title' id='exampleModalLabel'>
									User Wallet Transactions Details
								</h2>
								<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
							<div className='modal-body'>
								<div className='card-header'>
									<form>
										<div className='input-group input-group-flush input-group-merge'>
											<input
												type='search'
												className='form-control form-control-prepended search'
												onChange={this.handleWithdrawSearch}
												value={withdrawSearch}
												placeholder='Search'
											/>
											<div className='input-group-prepend'>
												<div className='input-group-text'>
													<span className='fe fe-search' />
												</div>
											</div>
										</div>
									</form>
								</div>
								<div
									className='table-responsive mb-0 table_height scroll_bar mb-0 x_scroll_width contest_table'
									style={{ maxHeight: "43rem" }}>
									<table className='table table-hover table-sm card-table '>
										<thead>
											<tr>
												<th>#</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("transection_id")}
													style={{ cursor: "pointer" }}>
													Wallet Type
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("contestId")}
													style={{ cursor: "pointer" }}>
													Contest Id
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("contestName")}
													style={{ cursor: "pointer" }}>
													Contest Tittle
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("createdAt")}
													style={{ cursor: "pointer" }}>
													Date And Time
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("isCredited")}
													style={{ cursor: "pointer" }}>
													Txn Type
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("id")}
													style={{ cursor: "pointer" }}>
													System Trans ID
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("balance")}
													style={{ cursor: "pointer" }}>
													Amount
												</th>
												<th
													className='textPink list-sort'
													data-sort='tables-row'
													onClick={() => this.sortByWithdraw("isActive")}
													style={{ cursor: "pointer" }}>
													Txn Status
												</th>
												<th className='textPink' data-sort='tables-row'>
													Remarks
												</th>
											</tr>
										</thead>

										{LoadingWithdraw ? (
											<tbody>
												<tr>
													<td colSpan='8'>
														<div className='spinner-border' role='status'>
															<span className='sr-only'>Loading...</span>
														</div>
													</td>
												</tr>
											</tbody>
										) : (
											<tbody>
												{withdrawList && withdrawList.length > 0 ? (
													withdrawList.map((obj, i) => (
														<tr>
															<td>{i + 1}</td>
															<td>{this.getWalletType(obj?.transection_id, obj?.cashWalletType)}</td>
															<td
																data-dismiss={obj?.contestId ? "modal" : ""}
																aria-label={obj?.contestId ? "Close" : ""}
																style={{ cursor: "pointer" }}
																onClick={() => this.redirectViewContest(obj?.contestId)}>
																{obj?.contestId || "-"}
															</td>
															<td
																data-dismiss={obj?.contestId ? "modal" : ""}
																aria-label={obj?.contestId ? "Close" : ""}
																style={{ cursor: "pointer" }}
																onClick={() => this.redirectViewContest(obj?.contestId)}>
																{obj?.contestName || "-"}
															</td>
															<td>{moment(obj?.createdAt).format("DD/MM/YYYY  HH:mm:ss")}</td>
															<td>{this.getTxnType(obj?.isCredited)}</td>
															<td>{obj?.id}</td>
															<td>{parseFloat(obj?.balance).toFixed(2)}</td>
															<td>{this.getTxnStatus(obj?.isActive)}</td>
															<td>{obj?.reason}</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan='9'>No data Found...</td>
													</tr>
												)}
											</tbody>
										)}
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(UserDetail);
